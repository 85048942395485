import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSafetyLeading } from 'queries/safety/safety'
import { ShortageFetchProps } from 'types/api'
import { IResponse } from 'utils/api'
import { TIME } from 'utils/misc'

export function useSafetyLeading({
  env,
  accessToken,
  location,
  dynamicSubPath,
}: ShortageFetchProps): IResponse {
  const { tenMinutes } = TIME
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.SAFETY_LEADING, location],
    () =>
      fetchSafetyLeading({
        env,
        accessToken,
        location,
        dynamicSubPath,
      }),
    {
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  return { data, isLoading, isError }
}
