import { PayrollFetchProps } from 'types/api'
import { TableData } from 'utils/data'
import qs from 'qs'
import { getLocationFilters } from 'utils/api'

export const fetchPayrollOvertimeData = async ({
  env,
  accessToken,
  location,
}: PayrollFetchProps): Promise<TableData> => {
  const { apiKey, baseUrl } = env
  let url = `${baseUrl}/mpm_aggregations/v1/payroll/overtime`

  const params = {
    ...getLocationFilters(location),
    key: apiKey,
  }
  const queryParams = qs.stringify(params)
  url += `?${queryParams}`

  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'x-api-key': apiKey,
    },
  }

  const response = await fetch(url, options)

  const parsedResponse = await response.json()

  return parsedResponse
}
