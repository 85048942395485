import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchSafetyStore } from 'queries/safety/safety'
import { ShortageFetchProps } from 'types/api'
import { ITitleListResponse } from 'utils/api'
import { TIME } from 'utils/misc'

export function useSafetyStore({
  env,
  accessToken,
  location,
  dynamicSubPath,
  filters,
}: ShortageFetchProps): ITitleListResponse {
  const { tenMinutes } = TIME
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.SAFETY_STORE, location],
    () =>
      fetchSafetyStore({
        env,
        accessToken,
        location,
        dynamicSubPath,
        filters,
      }),
    {
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  return { data, isLoading, isError }
}
