import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { useSearchParams } from 'react-router-dom'
import { useUser } from 'components/user-context'
import {
  usePayrollWorkgroup,
  usePayrollWorkgroupDrill,
} from 'hooks/payroll/workgroup'
import { useMPMEnv } from 'utils/env'
import {
  TableData,
  addLinkToFirstColumn,
  buildDataTableObject,
  insertRows,
  removeLinkFromFirstColumn,
} from 'utils/data'
import { getTableFormat } from 'utils/table'
import { Container } from 'components/container/Container'
import { UrlParams } from 'enums/url-params'
import { BasicPlaceholder } from 'components/placeholder'
import { useTranslation } from 'react-i18next'
import { messages } from '../messages'

export const Workgroup = () => {
  const { t } = useTranslation('payroll')
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const [parent, setParent] = useState<string>('')
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  let { data, isLoading, isError } = usePayrollWorkgroup({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
  })
  const { data: subData } = usePayrollWorkgroupDrill({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: parent,
  })
  const widths = [120, ...getTableFormat(data?.rows?.length ?? 20, 105)]

  const addChildRows = (data: TableData, subData: TableData) => {
    subData.rows = removeLinkFromFirstColumn(subData.rows)
    subData.rows = subData.rows.map((row) => {
      row[0].childOf = parent

      return row
    })
    const insertIndex =
      data.rows.findIndex((row) => row[0].value === parent) + 1
    const newData = { ...data }
    let newDataRows = [...data.rows]
    insertRows(newDataRows, insertIndex, subData.rows)
    newData.rows = newDataRows
    data = newData

    return data
  }
  if (data) {
    data.rows = addLinkToFirstColumn(data.rows)
    if (subData) {
      data = addChildRows(data, subData)
    }
  }

  const handleClick = (parentValue: string) => {
    if (data) {
      const match = data?.rows.filter((row) => row[0].childOf === parentValue)
      if (parent === parentValue || match.length > 0) {
        const filtered = data?.rows.filter(
          (row) => row[0].childOf !== parentValue,
        )
        data.rows = filtered
        setParent('')
      } else {
        setParent(parentValue)
      }
    }
  }

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="text" rows={1} />
        <BasicPlaceholder type="table" rows={8} columns={3} />
      </div>
    )
  }
  if (isError) return <h2>{t(messages.error, { ns: 'payroll' })}</h2>
  if (!data) return <h2>{t(messages.error, { ns: 'payroll' })}</h2>

  return (
    <Container>
      <p className="hc-fs-md">planned hours by work group</p>
      <Table
        data={buildDataTableObject({
          currentData: data,
          widths,
          handleClick,
        })}
        scrollableWidth={true}
        name={t(messages.workgroup, { ns: 'payroll' })}
      />
    </Container>
  )
}
