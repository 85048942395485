import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchLocationHierarchy } from 'queries/locationHierarchy/locationHierarchy'
import { LocationHierarchyFetchProps } from 'types/api'
import { ILocationHierarchyResponse } from 'utils/api'
import { TIME } from 'utils/misc'

export function useLocationHierarchy({
  env,
  accessToken,
  location,
  enabled,
}: LocationHierarchyFetchProps): ILocationHierarchyResponse {
  const { tenMinutes } = TIME
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.LOCATION_HIERARCHY, location],
    () =>
      fetchLocationHierarchy({
        env,
        accessToken,
        location,
      }),
    {
      enabled,
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  return { data, isLoading, isError }
}
