import { Layout } from 'components/layout'
import { useUser } from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { isStore } from 'utils/location'
import { SafetyNonStore } from './safety-nonstore'
import { SafetyStore } from './safety-store'

export const SafetyPage = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  const isStoreFlag = isStore(currentLocation)

  const safetyLayoutClass = isStoreFlag ? '' : 'hc-pa-none'

  return (
    <Layout heading={t('safety')} className={safetyLayoutClass}>
      <div className="pwa safety">
        {isStoreFlag ? <SafetyStore /> : <SafetyNonStore />}
      </div>
    </Layout>
  )
}
