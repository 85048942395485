import { ApiSubUrls, Apis } from 'enums/api-constants'
import { fetchGeneralData } from 'queries/fetchData'

interface Location {
  name: string
  short_name: string
  id: number
}

interface LocationHierarchy {
  region_id: number
  group_id: number
  district_id: number
  store_id: number
  location_type: string
  location_name: string
}

export interface LocationHierarchyModel {
  regions: Location[]
  groups: Location[]
  districts: Location[]
  stores: Location[]
  location_hierarchy: LocationHierarchy
}

export const fetchLocationHierarchy = fetchGeneralData<LocationHierarchyModel>(
  `${ApiSubUrls[Apis.AGGREGATIONS]}/location_hierarchy`,
)
