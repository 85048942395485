import { MPMEnvType } from '../utils/env'

export interface RawLocation {
  id: number | string
  name: string
  short_name: string
}

export interface UserDetailsData {
  appVersionDetails: [
    {
      android_forced_upgrade: boolean
      android_latest_version: string
      android_recommended_upgrade: boolean
      app_forced_message: string
      app_recm_message: string
      ios_forced_upgrade: boolean
      ios_recommended_upgrade: boolean
    },
  ]
  greenfield_mpm_user_groups: string[]
  locations: {
    company_location_details: RawLocation[]
    district_location_details: RawLocation[]
    group_location_details: RawLocation[]
    region_location_details: RawLocation[]
    store_location_details: RawLocation[]
  }
  showMessage: boolean
  user_details: {
    district_id: number
    first_name: string
    group_id: number
    last_name: string
    location_mapping: {
      location_id: number | string
      location: string
      location_name: string
      entitled_for_non_store: boolean
    }
    location_name: string
    location_type: string
    region_id: number
    store_id: number
    user_id: string
  }
}

export const fetchUserDetails = async (
  lanId: string,
  env: MPMEnvType,
  accessToken?: string,
) => {
  const { apiKey, baseUrl } = env

  const url = `${baseUrl}/mpm_team_members/v2/users?lan_id=${lanId}&key=${apiKey}`

  const userDetailsOptions = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${accessToken}`,
      'x-api-key': apiKey,
    },
    method: 'GET',
  }

  const fetched = await fetch(url, userDetailsOptions)
  const userDetailsJSON = await fetched.json()

  return userDetailsJSON as UserDetailsData
}
