import { isDistrict, isGroup, isRGD, isRegion, isStore } from 'utils/location'

export const getHeaderLocation = (location: string) => {
  if (isRGD(location)) {
    const locationId = location.slice(1)
    if (isRegion(location)) {
      return 'Region ' + locationId
    } else if (isGroup(location)) {
      return 'Group ' + locationId
    } else if (isDistrict(location)) {
      return 'District ' + locationId
    }
  } else if (!isStore(location)) {
    return 'Target'
  }

  return location
}
