import { useEffect, useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { useUser } from 'components/user-context'
import { useSearchParams } from 'react-router-dom'
import { useMPMEnv } from 'utils/env'
import { getTableFormat } from 'utils/table'
import { Container } from 'components/container/Container'
import { usePayrollOvertime } from 'hooks/payroll/overtime'
import { UrlParams } from 'enums/url-params'
import { BasicPlaceholder } from 'components/placeholder'
import { buildDataTableObject } from 'utils/data'
import { useTranslation } from 'react-i18next'
import { messages } from '../messages'

export const Overtime = () => {
  const { t } = useTranslation('payroll')
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const [tableObj, setTableObj] = useState({})
  const { data, isLoading, isError } = usePayrollOvertime({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
  })

  useEffect(() => {
    const widths = [110, ...getTableFormat(data?.rows?.length ?? 20, 100)]
    if (data) {
      const obj = buildDataTableObject({
        currentData: data,
        widths,
      })
      setTableObj(obj)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="text" rows={1} />
        <BasicPlaceholder type="table" rows={5} columns={3} />
      </div>
    )
  }
  if (isError) return <h2>{t(messages.error, { ns: 'payroll' })}</h2>
  if (!data) return <h2>{t(messages.error, { ns: 'payroll' })}</h2>

  return (
    <Container>
      <p className="hc-fs-md">weekly overtime % of total hours - trend</p>
      <Table
        data={tableObj}
        scrollableWidth={true}
        name={t(messages.overtime)}
      />
    </Container>
  )
}
