import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollWorkgroupData } from 'queries/payroll/workgroup/workgroup'
import { PayrollFetchProps } from 'types/api'

export function usePayrollWorkgroup({
  env,
  accessToken,
  location,
}: PayrollFetchProps) {
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.PAYROLL_WORKGROUP, location],
    () =>
      fetchPayrollWorkgroupData({
        env,
        accessToken,
        location,
      }),
  )

  return { data, isLoading, isError }
}
