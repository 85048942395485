import { useAuth } from '@praxis/component-auth'
import { Grid, Table } from '@enterprise-ui/canvas-ui-react'
import { TitledTableData, buildDataTableObject } from 'utils/data'
import {
  getDynamicSubPath,
  getLocationFilters,
  setFirstColumnInHeaders,
} from './helpers'
import { useSearchParams } from 'react-router-dom'
import { useUser } from 'components/user-context'
import { useMPMEnv } from 'utils/env'
import { UrlParams } from 'enums/url-params'
import { useSafetyStore } from 'hooks/safety/useSafetyStore'
import { BasicPlaceholder } from 'components/placeholder'
import { useTranslation } from 'react-i18next'

export const SafetyStore = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const dynamicSubPath = getDynamicSubPath(currentLocation)
  const filters = getLocationFilters(currentLocation)
  let { data, isLoading, isError } = useSafetyStore({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath,
    filters,
  })

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError) return <h1>Error</h1>
  if (!data) return <h1>An issue with data</h1>

  const [lagging, total, leading] = data

  total.table.headings = setFirstColumnInHeaders(total.table, 'timeframe')
  leading.table.headings = setFirstColumnInHeaders(leading.table, 'indicator')

  const translatedData = (data: TitledTableData) => {
    data.table.headings = data.table.headings?.map((heading) => {
      return { ...heading, value: t(heading.value) }
    })
    data.table.rows = data.table.rows.map((row) => {
      let firstCell = row[0]
      firstCell.value = t(firstCell.value)

      return { ...row, firstCell }
    })
  }

  translatedData(lagging)
  translatedData(total)
  translatedData(leading)

  return (
    <div>
      {[lagging, total, leading].map((titledTable: TitledTableData) => {
        const { title, table } = titledTable

        return (
          <Grid.Container className="hc-pv-md">
            <Grid.Item xs={12} className="hc-pb-none">
              <p className="hc-pa-min hc-lh-expanded hc-txt-lowercase">
                {t(title)}
              </p>
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pt-none">
              <Table
                data={buildDataTableObject({
                  currentData: table,
                  widths: [],
                })}
                name={title}
              />
            </Grid.Item>
          </Grid.Container>
        )
      })}
    </div>
  )
}
