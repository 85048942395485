import { useAuth } from '@praxis/component-auth'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { Container } from 'components/container/Container'
import { buildDataTableObject } from 'utils/data'
import {
  addHyperLinkToFirstColumn,
  getDynamicSubPath,
  setFirstColumnInHeaders,
} from './helpers'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useUser } from 'components/user-context'
import { useMPMEnv } from 'utils/env'
import { UrlParams } from 'enums/url-params'
import { useSafetyTotal } from 'hooks/safety/useSafetyTotal'
import { BasicPlaceholder } from 'components/placeholder'
import { useTranslation } from 'react-i18next'
import { messages } from './messages'

export const SafetyTotal = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const { pathname } = useLocation()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const dynamicSubPath = getDynamicSubPath(currentLocation)
  let { data, isLoading, isError } = useSafetyTotal({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath,
  })

  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError) return <h1>Error</h1>
  if (!data) return <h1>{messages.error}</h1>

  data.headings = setFirstColumnInHeaders(data, 'Location')
  data = addHyperLinkToFirstColumn(data, pathname)

  data.headings = data.headings?.map((heading) => {
    return { ...heading, value: t(heading.value) }
  })

  return (
    <Container>
      <p>{t(messages.nonStoreHeading)}</p>
      <Table
        data={buildDataTableObject({
          currentData: data,
          widths: [150, 200, 150, 150],
          pinFirstColumn: true,
        })}
        name={t(messages.totalTitle)}
      />
    </Container>
  )
}
