import { useState } from 'react'
import { useAuth } from '@praxis/component-auth'
import { BasicPlaceholder } from 'components/placeholder'
import { useUser } from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useShortage } from 'hooks/shortage/useShortage'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useMPMEnv } from 'utils/env'
import { getTableFormat } from 'utils/table'
import { messages } from 'pages/payroll/messages'
import { TitledFilteredTable } from 'components/titled-filtered-table/title-filtered-table'
import { addHyperLinkToFirstColumn } from 'pages/payroll/helpers'
import {
  TableData,
  addLinkToFirstColumn,
  insertRows,
  removeLinkFromFirstColumn,
} from 'utils/data'
import { useShortageTopTen } from 'hooks/shortage/useShortageTopTen'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { ShortageCard } from './components/card'
import { getLocationFilters } from 'utils/api'

export const ShortageNonStore = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const { pathname } = useLocation()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const [parent, setParent] = useState<string>('')

  const filters = getLocationFilters(currentLocation)
  const { data, isLoading, isError } = useShortage({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    filters,
  })

  const { data: subData } = useShortageTopTen({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: parent,
  })

  if (isLoading || !data) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError) {
    return <h2>{t(messages.error, { ns: 'payroll' })}</h2>
  }

  const [thisYear, topTen] = data
  let { title: tyTitle, table: tyTable } = thisYear
  let { title: ttTitle, table: ttTable } = topTen

  const handleClick = (parentValue: string) => {
    if (ttTable) {
      const match = ttTable?.rows.filter(
        (row) => row[0].childOf === parentValue,
      )
      if (parent === parentValue || match.length > 0) {
        const filtered = ttTable?.rows.filter(
          (row) => row[0].childOf !== parentValue,
        )
        ttTable.rows = filtered
        setParent('')
      } else {
        setParent(parentValue)
      }
    }
  }

  const addChildRows = (data: TableData, subData: TableData) => {
    subData.rows = removeLinkFromFirstColumn(subData.rows)
    subData.rows = subData.rows.map((row) => {
      row[0].childOf = parent

      return row
    })
    const insertIndex =
      ttTable.rows.findIndex((row) => row[0].value === parent) + 1
    const newData = { ...ttTable }
    let newDataRows = [...ttTable.rows]
    insertRows(newDataRows, insertIndex, subData.rows)
    newData.rows = newDataRows
    data = newData

    return data
  }

  tyTable = addHyperLinkToFirstColumn(tyTable, currentLocation, pathname)
  ttTable.rows = addLinkToFirstColumn(ttTable.rows)
  if (subData) {
    ttTable = addChildRows(ttTable, subData)
  }

  const widths = [150, ...getTableFormat(tyTable.headings?.length ?? 20, 90)]

  return (
    <>
      <Grid.Container className="hc-pv-md">
        <TitledFilteredTable
          title={t(tyTitle)}
          data={tyTable}
          widths={widths}
          pinFirstColumn={true}
          key={tyTitle}
        />
      </Grid.Container>
      <Grid.Container className="hc-pv-md hc-mt-md">
        <Grid.Item xs={12}>
          <ShortageCard
            dollars={tyTable.footer?.[3].value}
            percent={tyTable.footer?.[7].value}
          />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container className="hc-pv-md hc-mt-md">
        <TitledFilteredTable
          title={t(ttTitle)}
          data={ttTable}
          widths={widths}
          handleClick={handleClick}
          pinFirstColumn={true}
          key={ttTitle}
        />
      </Grid.Container>
    </>
  )
}
