import { useState } from 'react'
import EnterpriseIcon, {
  CartIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CreditCardIcon,
} from '@enterprise-ui/icons'
import {
  Button,
  ButtonGroup,
  Grid,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { Container } from 'components/container/Container'
import { useUser } from 'components/user-context'
import { UrlParams } from 'enums/url-params'
import { useSalesOverview } from 'hooks/sales/useSalesOverview'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { useMPMEnv } from 'utils/env'
import {
  formatCurrency,
  formatPercent,
  getDynamicSubPath,
  getLocationFilter,
  getPercentClass,
  getPercentComplete,
  getRealPercent,
} from '../helpers'
import { BasicPlaceholder } from 'components/placeholder'
import { messages } from '../messages'

export const SalesOverview = () => {
  const { t } = useTranslation()
  const [params] = useSearchParams()
  const { location } = useUser()
  const env = useMPMEnv()
  const { session } = useAuth()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName

  const locationFilter = getLocationFilter(currentLocation)
  const filters = {
    date: new Date().toISOString().split('T')[0],
    source: 'greenfield',
    ...locationFilter,
  }

  const [timePeriodIndex, setTimePeriodIndex] = useState<number>(0)

  const result = useSalesOverview({
    env,
    accessToken: session?.accessToken ?? '',
    location: currentLocation,
    dynamicSubPath: getDynamicSubPath(currentLocation),
    filters,
  })

  const [today, yesterday, weekToDate, lastWeek, monthToDate, yearToDate] =
    result

  // if any time period is loading, show a placeholder
  const isLoading = result.some((r) => r.isLoading)
  if (isLoading) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={10} columns={3} />
      </div>
    )
  }

  // if every time period has an error, show an error message
  const isError = result.every((r) => r.isError)
  if (isError) {
    return <h2>{t(messages.error, { ns: 'sales' })}</h2>
  }

  // build an object with all time periods accounted for, but if any don't have a valid data object (i.e. it's null via 404), filter it out
  const payload = [
    {
      title: 'today',
      subtitle: '',
      data: today.data,
    },
    {
      title: 'yesterday',
      subtitle: '',
      data: yesterday.data,
    },
    {
      title: 'week to date',
      subtitle: '',
      data: weekToDate.data,
    },
    {
      title: 'last week',
      subtitle: '',
      data: lastWeek.data,
    },
    {
      title: 'month to date',
      subtitle: '',
      data: monthToDate.data,
    },
    {
      title: 'year to date',
      subtitle: '',
      data: yearToDate.data,
    },
  ].filter((item) => item.data)

  const currentDataSet = payload[timePeriodIndex].data[0]
  const uniqueKey: string =
    Object.keys(currentDataSet).find(
      (key) =>
        ![
          'id',
          'location_id',
          'date',
          'sales_by_location',
          'region_id',
          'group_id',
          'district_id',
          'store_id',
        ].includes(key),
    ) || ''
  const currentData = currentDataSet[uniqueKey]
  const timePeriods = payload.map((item) => item.title)
  const currentTimePeriod = timePeriods[timePeriodIndex]
  const realGoalPercent = getRealPercent(
    currentData.forecast_pct,
    currentData.forecast_status,
  )
  const realGoalPercentFormatted = formatPercent(realGoalPercent)
  const salesFormatted = formatCurrency(currentData.sales)
  const todayLink = (
    <Button type="ghost" className="hc-ma-none hc-pa-none">
      <Link to={`hourly`} className="hc-fs-lg">
        {salesFormatted}
      </Link>
    </Button>
  )
  const todaySalesElement =
    currentTimePeriod === 'today' ? todayLink : salesFormatted
  return (
    <Container name="sales overview" role="main">
      <Grid.Container align="center" justify="space-between">
        <Grid.Item xs={4} className="hc-fs-md hc-ph-none">
          {payload[timePeriodIndex].title}
        </Grid.Item>
        <Grid.Item xs={4}>
          <ButtonGroup className={'time-periods'}>
            {payload.map((item: any, index: number) => {
              return (
                <Button
                  type={index === timePeriodIndex ? 'primary' : 'secondary'}
                  onClick={() => setTimePeriodIndex(index)}
                  key={payload[index].title}
                >
                  {item.title}
                </Button>
              )
            })}
          </ButtonGroup>
        </Grid.Item>
        <Grid.Item xs={4}></Grid.Item>
      </Grid.Container>
      <Grid.Container
        align="center"
        justify="space-around"
        className="hc-pb-lg"
      >
        <Grid.Item xs={9} className="hc-ph-none">
          <span className="hc-fs-lg">{todaySalesElement}</span> of{' '}
          {formatCurrency(currentData.forecast)}{' '}
          <span className={getPercentClass(realGoalPercent)}>
            {realGoalPercentFormatted}
          </span>
          <ProgressBar
            percentComplete={getPercentComplete(realGoalPercent)}
            className={realGoalPercent >= 80 ? 'success' : 'error'}
          />
        </Grid.Item>
      </Grid.Container>
      <SalesOverviewDataRow
        label="originated"
        dollars={currentData.sales_breakdown.originated}
        percent={currentData.sales_breakdown.originated_pct}
        isChild={false}
      />
      {currentTimePeriod !== 'today' && (
        <SalesOverviewDataRow
          label="line busting"
          dollars={currentData.sales_breakdown.line_bust}
          percent={currentData.sales_breakdown.line_bust_pct}
        />
      )}
      <SalesOverviewDataRow
        label="fulfilled"
        dollars={currentData.fulfilled_sales}
        percent={currentData.fulfilled_pct}
        isChild={false}
      />
      <SalesOverviewDataRow
        label="ship from store"
        dollars={currentData.sales_breakdown.ship_from_store}
        percent={currentData.sales_breakdown.ship_from_store_pct}
      />
      {currentTimePeriod !== 'today' && (
        <SalesOverviewDataRow
          label="drive-up"
          dollars={currentData.sales_breakdown.drive_up}
          percent={currentData.sales_breakdown.drive_up_pct}
        />
      )}
      {currentTimePeriod !== 'today' && (
        <SalesOverviewDataRow
          label="order pickup"
          dollars={currentData.sales_breakdown.order_pickup}
          percent={currentData.sales_breakdown.order_pickup_pct}
        />
      )}
      {currentTimePeriod !== 'today' && (
        <SalesOverviewDataRow
          label="shipt"
          dollars={currentData.sales_breakdown.shipt}
          percent={currentData.sales_breakdown.shipt_pct}
        />
      )}
      {currentTimePeriod !== 'today' && (
        <SalesOverviewDataRow
          label="other sales"
          isChild={false}
          showData={false}
        />
      )}
      {currentTimePeriod !== 'today' && (
        <SalesOverviewDataRow
          label="save the sale"
          dollars={currentData.sales_breakdown.mycheckout}
          percent={currentData.sales_breakdown.mycheckout_pct}
        />
      )}
      <SalesOverviewDataRow
        label="goal"
        dollars={currentData.goal}
        percent={currentData.goal_pct}
        isChild={false}
      />
      <SalesOverviewDataRow
        label="last year"
        isChild={false}
        showData={false}
      />
      <SalesOverviewDataRow
        label="comp %"
        dollars={
          currentTimePeriod === 'today'
            ? currentData.last_year_total_sales
            : currentData.last_year_sales
        }
        percent={currentData.ly_admapped}
      />
      <SalesOverviewDataRow
        label="originated comp %"
        dollars={currentData.last_year_total_sales_originated}
        percent={currentData.originated_ly_admapped}
      />
      <SalesOverviewDataRow
        label="fulfilled comp %"
        dollars={currentData.last_year_total_sales_fulfilled}
        percent={currentData.fulfilled_ly_admapped}
      />
      <Grid.Container>
        <Grid.Item xs={12} className="hc-ph-none">
          <hr />
        </Grid.Item>
      </Grid.Container>
      <Grid.Container>
        <Grid.Item xs={6} className="hc-pl-none">
          <Grid.Container>
            <Grid.Item>
              <strong className="heading-with-icon">
                <EnterpriseIcon icon={CartIcon} />
                basket size
              </strong>
            </Grid.Item>
          </Grid.Container>
          <SalesOverviewSmallwDataRow
            label="total"
            dollars={currentData.average_basket_size}
          />
          <SalesOverviewSmallwDataRow
            label="ly"
            dollars={currentData.last_year_average_basket_size}
          />
          {currentTimePeriod !== 'today' && (
            <SalesOverviewSmallwDataRow
              label="b/p to ly"
              dollars={currentData.basket_size_comp}
              arrow={currentData.basket_icon}
            />
          )}
          {currentTimePeriod !== 'today' && (
            <SalesOverviewSmallwDataRow
              label="b/p to ly %"
              dollars={currentData.basket_size_comp_pct}
              arrow={currentData.basket_icon}
            />
          )}
        </Grid.Item>
        <Grid.Item xs={6} className="hc-pl-none">
          <Grid.Container>
            <Grid.Item>
              <strong className="heading-with-icon">
                <EnterpriseIcon icon={CreditCardIcon} />
                transactions
              </strong>
            </Grid.Item>
          </Grid.Container>
          <SalesOverviewSmallwDataRow
            label="total"
            dollars={currentData.total_transaction_count}
            edgePadding={true}
          />
          <SalesOverviewSmallwDataRow
            label="ly"
            dollars={currentData.last_year_total_transaction_count}
            edgePadding={true}
          />
          {currentTimePeriod !== 'today' && (
            <SalesOverviewSmallwDataRow
              label="b/p to ly"
              dollars={currentData.transaction_comp}
              arrow={currentData.transaction_icon}
              edgePadding={true}
            />
          )}
          {currentTimePeriod !== 'today' && (
            <SalesOverviewSmallwDataRow
              label="b/p to ly %"
              dollars={currentData.transaction_comp_pct}
              arrow={currentData.transaction_icon}
              edgePadding={true}
            />
          )}
        </Grid.Item>
      </Grid.Container>
    </Container>
  )
}

type SalesOverviewDataRowProps = {
  label: string
  showData?: boolean
  isChild?: boolean
  dollars?: number
  percent?: number
}
const SalesOverviewDataRow = ({
  label,
  showData = true,
  isChild = true,
  dollars,
  percent,
}: SalesOverviewDataRowProps) => {
  if (!showData) {
    return (
      <Grid.Container>
        <Grid.Item xs={6} className={isChild ? 'hc-pl-expanded' : 'hc-ph-min'}>
          {isChild ? label : <strong>{label}</strong>}
        </Grid.Item>
      </Grid.Container>
    )
  }
  return (
    <Grid.Container>
      <Grid.Item xs={6} className={isChild ? 'hc-pl-expanded' : 'hc-ph-min'}>
        {isChild ? label : <strong>{label}</strong>}
      </Grid.Item>
      {dollars && (
        <Grid.Item xs={3} className="hc-ph-min hc-ta-right">
          {formatCurrency(dollars)}
        </Grid.Item>
      )}
      {percent ? (
        <Grid.Item xs={3} className="hc-ph-min hc-ta-right">
          <Grid.Item className={getPercentClass(percent)}>
            {formatPercent(percent) || '0.00%'}
          </Grid.Item>
        </Grid.Item>
      ) : (
        <Grid.Item xs={3} className="hc-ph-min hc-ta-right">
          <Grid.Item>{'0.00'}%</Grid.Item>
        </Grid.Item>
      )}
    </Grid.Container>
  )
}

type SalesOverviewSmallDataRowProps = {
  label: string
  dollars: number
  arrow?: string
  edgePadding?: boolean
}
const SalesOverviewSmallwDataRow = ({
  label,
  dollars,
  arrow,
  edgePadding = false,
}: SalesOverviewSmallDataRowProps) => {
  const icon = arrow === 'up' ? ChevronUpIcon : ChevronDownIcon
  const iconClass = arrow === 'up' ? 'hc-clr-success' : 'hc-clr-error'
  return (
    <Grid.Container className="hc-pa-none">
      <Grid.Item xs={6} className="hc-pr-min">
        {label}
      </Grid.Item>
      <Grid.Item
        xs={6}
        className={`hc-ta-right ${edgePadding ? 'hc-ph-min' : 'hc-pl-min'}`}
      >
        {formatCurrency(dollars)}
        {arrow && (
          <>
            <EnterpriseIcon
              icon={icon}
              size="sm"
              className={`${iconClass} hc-ml-min`}
            />
          </>
        )}
      </Grid.Item>
    </Grid.Container>
  )
}
