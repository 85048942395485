import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchPayrollLocationMonthlyData } from 'queries/payroll/location/location'
import { PayrollFetchProps } from 'types/api'
import { IResponse, apiNotEnabled } from 'utils/api'
import { TIME } from 'utils/misc'

export function usePayrollLocationMonthly({
  env,
  accessToken,
  location,
  dynamicSubPath,
  filters,
}: PayrollFetchProps): IResponse {
  const { tenMinutes } = TIME
  const enabled = true
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.PAYROLL_LOCATION, location],
    () =>
      fetchPayrollLocationMonthlyData({
        env,
        accessToken,
        location,
        dynamicSubPath,
        filters,
      }),
    {
      enabled,
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  if (!enabled) {
    return apiNotEnabled()
  }

  return { data, isLoading, isError }
}
