import { BasicPlaceholder } from 'components/placeholder'
import {
  DataTableObjectType,
  TableData,
  buildDataTableObject,
} from 'utils/data'
import { getTableFormat } from 'utils/table'
import { getLocationTableIndexes, pickData } from '../helpers'
import { Table } from '@enterprise-ui/canvas-ui-react'
import { useTranslation } from 'react-i18next'
import { messages } from '../messages'

interface INonStoreLocation {
  data: TableData | undefined
  isLoading: boolean
  isError: boolean
  currentLocation: string
  bottomLeftCell: string
}
export const NonStoreLocation = ({
  data,
  isLoading,
  isError,
  currentLocation,
  bottomLeftCell,
}: INonStoreLocation) => {
  const { t } = useTranslation(['general', 'payroll'])
  if (isLoading || !data) {
    return (
      <div className="placeholder-container" data-testid="placeholder">
        <BasicPlaceholder type="table" rows={5} columns={5} />
      </div>
    )
  }
  if (isError) return <h2>{t(messages.error, { ns: 'payroll' })}</h2>

  data.headings = data?.headings?.map((heading) => ({
    ...heading,
    value: t(heading.value),
  }))

  const widths = [100, ...getTableFormat(data?.rows?.length ?? 20, 85)]
  const { startIndex: tableStartIndex, endIndex: tableEndIndex } =
    getLocationTableIndexes(currentLocation)
  const objParams = {
    currentData: pickData(
      data,
      currentLocation,
      tableStartIndex,
      tableEndIndex,
      t('loc'),
    ),
    widths,
    bottomLeftCell,
  } as DataTableObjectType
  const obj = buildDataTableObject(objParams)
  return (
    <Table
      data={obj}
      scrollableWidth={true}
      name={t(messages.nonStoreLocation)}
    />
  )
}
