import { useState } from 'react'
import { Tabs } from '@enterprise-ui/canvas-ui-react'
import { Layout } from 'components/layout'
import { useUser } from 'components/user-context'
import { useSearchParams } from 'react-router-dom'
import { UrlParams } from 'enums/url-params'
import { clearLocationParam } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { SalesOverview } from './overview/overview'

export const SalesPage = () => {
  const [params, setParams] = useSearchParams()
  const { location } = useUser()
  const currentLocation =
    params.get(UrlParams.LOCATION_ID) ?? location.shortName
  window.onpopstate = (e) => {
    clearLocationParam({ params, setParams })
  }

  return <Sales key={currentLocation} />
}

export const Sales = () => {
  const { t } = useTranslation()
  const tabs = ['overview', 'details', 'location']
  const [activeTab, setActiveTab] = useState<string>(tabs[0])

  const handleTabSelect = (_event: Event, tab: { name: string }) =>
    setActiveTab(tab.name)

  return (
    <Layout heading="sales" className="hc-pa-none">
      <Tabs
        activeTab={activeTab}
        className="tabs hc-pv-dense"
        justified
        disableAdaptive={true}
        onTabSelect={handleTabSelect}
      >
        {tabs.map((tab: string) => (
          <Tabs.Item className="hc-bg-tabsItem" key={tab} name={tab}>
            {t(tab)}
          </Tabs.Item>
        ))}
        <Tabs.Content className="tabsContent" name={tabs[0]}>
          <SalesOverview />
        </Tabs.Content>
        <Tabs.Content className="tabsContent" name={tabs[1]}>
          <h1>Details</h1>
        </Tabs.Content>
        <Tabs.Content className="tabsContent" name={tabs[2]}>
          <h1>Location</h1>
        </Tabs.Content>
      </Tabs>
    </Layout>
  )
}
