import { useEffect } from 'react'
import {
  Outlet,
  useLocation,
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'
import { useAuth } from '@praxis/component-auth'
import '@enterprise-ui/canvas-ui-css'
import { AnalyticsProvider } from '@praxis/component-analytics'

import Authenticate from 'pages/authenticate'
import { SalesByLocationRoute, SalesRoute } from 'pages/sales'
import { HomePage } from 'pages'
import { SettingsRoute } from 'pages/settings'
import { LocationSearchRoute } from 'pages/location-search'
import { useMPMEnv } from 'utils/env'
import { checkRoutePermissions } from 'utils/authentication'
import { Unauthorized } from 'pages/unauthorized'
import { useUser } from 'components/user-context'
import { PayrollRoute } from 'pages/payroll'
import { ShortageRoute } from 'pages/shortage'
import { SafetyRoute } from 'pages/safety/routes'
import { SalesHourlyRoute } from 'pages/sales/routes'

const HomeRoute: RouteObject = {
  path: '/',
  index: true,
  element: <HomePage />,
}

/**
 * Ensures that the current user has the correct permissions (AD Groups) to
 * access a given route. If not, user will be shown the <Unauthorized /> page.
 *
 * The route to AD Group access mappings are configured in the public/config.json file.
 */
function AuthorizedRoutes() {
  const env = useMPMEnv()
  const { permissionsByKPI } = env
  let { pathname: path } = useLocation()
  const { greenfieldGroups: userPermissions } = useUser()

  if (path.lastIndexOf('/') > 0) {
    path = path.slice(0, path.lastIndexOf('/'))
  }

  const isAuthorized = checkRoutePermissions({
    path,
    userPermissions,
    permissionsByKPI,
  })

  if (!isAuthorized) {
    return <Unauthorized />
  }
  return <Outlet />
}

const AuthenticateRoute: RouteObject = {
  path: '/authenticate',
  element: <Authenticate />,
}

/**
 * The root route. Provides analytics and then loads the current route.
 * Must be called from within a router context.
 */
const Root = () => {
  const env = useMPMEnv()

  const eventManagerConfiguration = {
    appName: env.firefly.appName,
    apiKey: env.apiKey,
    eventManagerKey: env.firefly.eventManagerKey,
    url: env.firefly.baseUrl + '/' + env.firefly.url,
  }

  return (
    <AnalyticsProvider
      eventManagerConfiguration={eventManagerConfiguration}
      trackLocations
    >
      <AuthorizedRoutes />
    </AnalyticsProvider>
  )
}

/**
 * Whenever new route objects are created, they must be added as children here
 * or children of one of the routes given here.
 */
export const RootRoute: RouteObject = {
  path: '/',
  element: <Root />,
  children: [
    HomeRoute,
    SalesRoute,
    LocationSearchRoute,
    AuthenticateRoute,
    PayrollRoute,
    SalesRoute,
    SalesByLocationRoute,
    SalesHourlyRoute,
    SettingsRoute,
    ShortageRoute,
    SafetyRoute,
  ],
}

const router = createBrowserRouter([RootRoute])

/**
 * Ensures login happens, if necessary. Then sets up the routing.
 */
const Router = () => {
  const { isAuthenticated, login } = useAuth()

  if (!isAuthenticated()) {
    login()
  }

  const { setLocation } = useUser()

  useEffect(() => {
    setLocation({
      locationId: 'Target',
      shortName: 'Company',
      name: 'Target',
    })
  }, [setLocation])

  return <RouterProvider router={router} />
}

export default Router
