import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/query-keys'
import { fetchMerchandise } from 'queries/shortage/shortage'
import { ShortageFetchProps } from 'types/api'
import { IResponse, apiNotEnabled } from 'utils/api'
import { TIME } from 'utils/misc'

export function useMerchandise({
  env,
  accessToken,
  location,
  dynamicSubPath,
  filters,
}: ShortageFetchProps): IResponse {
  const dynamicSubPathArr = dynamicSubPath?.split('|')
  let dynamicUrl = ''
  if (dynamicSubPathArr) {
    const [group = '', division = '', department = ''] = dynamicSubPathArr
    dynamicUrl = [
      group && `groups/${group}`,
      division && `divisions/${division}`,
      department && `departments/${department}`,
    ]
      .filter(Boolean)
      .join('/')
  }

  const { tenMinutes } = TIME
  const enabled = true
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.SHORTAGE_MERCH, location, dynamicSubPath?.toString()],
    () =>
      fetchMerchandise({
        env,
        accessToken,
        location,
        dynamicSubPath: dynamicUrl,
        filters,
      }),
    {
      enabled,
      staleTime: tenMinutes,
      cacheTime: tenMinutes,
    },
  )

  if (!enabled) {
    return apiNotEnabled()
  }

  return { data, isLoading, isError }
}
