import { LocationHierarchyModel } from 'queries/locationHierarchy/locationHierarchy'
import { TableData, TitledTableData } from './data'
import {
  isRegion,
  isStore,
  isGroup,
  isDistrict,
  isStoreOrRGD,
} from 'utils/location'
export interface IResponse {
  data: TableData | undefined
  isLoading: boolean
  isError: boolean
}

export interface ITitleListResponse {
  data: TitledTableData[] | undefined
  isLoading: boolean
  isError: boolean
}

export interface ILocationHierarchyResponse {
  data: LocationHierarchyModel | undefined
  isLoading: boolean
  isError: boolean
}

export const getLocationFilters = (location: string) => {
  if (!isStoreOrRGD(location)) {
    return undefined
  }

  let filters = {} as { [key: string]: string }
  if (isRegion(location)) {
    filters.filter_dimension = 'regions'
  } else if (isGroup(location)) {
    filters.filter_dimension = 'groups'
  } else if (isDistrict(location)) {
    filters.filter_dimension = 'districts'
  } else if (isStore(location)) {
    filters.filter_dimension = 'store'
  }
  filters.filter_value = location

  return filters
}

export const apiNotEnabled = () => ({
  data: undefined,
  isLoading: false,
  isError: false,
})
